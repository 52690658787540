path {
  pointer-events: all;
}
path:hover {
  opacity: 0.5;
  cursor: pointer;
}
.card {
  width: 90%;
  height: 800px;
  background-color: #fff;
  margin: 10px auto;
  box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -webkit-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -moz-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  border-radius: 22px;
  margin-bottom: 2rem !important;
  padding: 2rem;
}
.card h2 {
  font-weight: 700;
  font-size: 50px;
  color: rgba(21, 55, 35, 0.8);
}
.card p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 2rem;

  color: rgba(21, 55, 35, 0.6);
}

.react_map{
  margin: 1rem auto;
  /* width: 400px; */
}

@media screen and (max-width: 768px) {
  

}