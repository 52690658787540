.commitment{
    height: 100%;
    background-image: url(../../images/forest.png);
    background-size: cover;
    background-repeat: no-repeats;
    color: #fff;
}
.bg_shadow{
    height: 100%;
    background-color: rgba(255,255,255,0.8);
}
.container_box{
    width: 90%;
    padding: 1rem 0rem;
    color: #1E2B22;
    text-align: center;
    margin: auto;
}
.container_box h1{
    font-size: 2rem;
    font-weight: 700;
    margin: 1rem 0;
}
.info{ 
    font-size: 1.7rem;
}

.container_box__card{
    /* height: 636px; */
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 2rem;
}

@media screen and (max-width:1200px){
    .container_box{
        width: 90%;
        height: 100%;
    }
    .container_box h1{
        font-size: 1.8rem;
        margin-bottom: 1.8rem;
    }
    .container_box__card{
        display: flex;
        flex-direction: column;
    }
    .info{
        font-size: 1.3rem;
    }
}
@media screen and (max-width:674px){
    .commitment{
        height: 100%;
    }
    .bg_shadow{
        height: 100%;
    }
    .container_box h1{
        font-size: 1.5rem;
    }
    .info{
        font-size: 1.2rem;
    }
    .container_box__card{
        display: flex;
        flex-direction: column;
        margin-bottom: .5rem;
        gap: 0;

    }
}