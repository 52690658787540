hr{
    width: 95%;
    margin:2rem auto;
    color: rgb(240, 240, 240);
    display: none;
}
.sub_header{ 
    
    background: url(../../images/insight.jpg);
    height: 100vh;
    background-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}
.sub_header::after{
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    opacity: 0.5;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.sub_header__box{
    color: #fff;
    position: absolute;
    bottom: 20%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.sub_header__box h1{
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 15px;
    padding-left: 2rem;
    margin-bottom: 0;
}
.sub_header__box h3{
    font-size: 1.7rem;
    font-weight: 200;

}
.container_insight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3rem auto;
    width: 80%;
}
.image_box{
    width: 45%;
    max-height: 600px;
    border-radius: 1rem;
    /* overflow: hidden; */
    align-self: flex-start;
    position: relative;
}
.image_box img{
    width: 100%;
    height: 60%;
    transition: all 1s;
}.image_box::before{
    content: "Hover on image below";
    background-color: rgb(3, 84, 7);
    color: #fff;
    padding: .5rem;
    font-size: 15px;
    font-weight: 800;
    border-radius: .5rem;
    position: absolute;
    top: 0;
    left: 30%; 
    transform: translate( -50%);
    animation: zoom 1s linear .5s infinite ;
}
.image_desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@keyframes zoom {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.image_box img:hover{
    transform: scale(1.5);
    margin-left: 30%
}
.image_articles{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}
.image_articles h2{
    margin-bottom: 2rem;
}
.image_article__box{
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
color: rgba(21, 55, 35, 0.8);
margin: 1rem 0;
gap: 20px;
}
.image_article__box:hover{
    color: rgb(9, 203, 9);
}
.image_article__box .image{
    width: 40%;
    height: 100%;
    border-radius: .5rem;
    overflow: hidden;
}
.image_article__box .image img{
    width: 100%;
    height: 100%;
}
.image_article__box p{
    width: 57%;
}
@media screen and (max-width: 768px){
    .container_insight{
        flex-direction: column;

    }
    .image_box{
        width: 100%;
        margin-bottom: 5rem;
    }
    .sub_header__box h1{
        font-size: 3rem;
    }
    .sub_header__box h3{
        font-size: 1.2rem;
    
    }
    hr{
        display: block;
    }
    .image_articles{
        width: 100%;
    }
}
