.container{
    background-color: #E5E5E5;
    height: 100vh;
    overflow-x: hidden;
}
.container_box{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin: 5rem auto;
    background-color: #E5E5E5;
}
@media screen and (max-width:1200px) {
    .container_box{
        width: 95%;
        gap: 1rem;
    }
}
@media screen and (max-width:768px) {
    .container_box{
        width: 85%;
        flex-direction: column;
    }
}