.title{
     text-align: center;
     color: #344339;
     font-size: 6rem; 
     margin: 1rem;
}
@media screen and (max-width: 1200px) {
     .title{
          font-size: 4rem;
     }
}
@media screen and (max-width: 768px) {
     .title{
          margin-top: 7rem;
          font-size: 2.5rem;
     }
}