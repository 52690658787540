.card {
    width: 30%;
    /* height: 100%; */
    height: 678px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(21, 55, 35, 0.8);
    box-shadow: 0px 4px 25px rgba(30, 42, 34, 0.25);
    padding: 1.5rem 2.2rem;
    border-radius: 1.5rem;
    text-align: center;


 
  }
  .card .image {
    width: 15rem;
    height: 15rem;
 
    /* position: absolute; */
  }
  .card .image img{
    width: 100%;
    height: 100%;
  }
  .card h1{
    font-size: 1.7rem;
    margin-bottom: 0.2rem;
  }

  .card h2 {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
    font-weight: normal;
  }
  .card h3{
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .card p {
    text-align: left;
    font-size: 1rem;
    /* padding-bottom: 2rem; */
  }
  
  @media screen and (max-width:1200px){
    .card{
      width: 70%;
      height: 100%;
      padding: 1rem 2rem;
      margin: 1rem auto;
      border-radius: 1rem;
  
  
    }
    .card h2{
      font-size: 1.5rem;
    }
    .card p{
      font-size: 1.3rem;
    }
  }
  @media screen and (max-width:674px){
    .card{
      width: 100%;
      height: 100%;
      padding: .5rem;
      border-radius: 0.7rem;
  
    }
    .card .image {
      width: 10rem;
      height: 10rem;
    }
    .card .image img{
      width: 100%;
      height: 100%;
    }
    .card h1{
      font-size: 1.3rem;
    }
  
    .card h2 {
      font-size: 1.1rem;
    }
    .card p {
      text-align: left;
      font-size: 1rem;
      padding-bottom: 1rem;
    }
  }