.container{
    height: 100vh;
    background-image: url(../../images/forest.png);
    background-size: cover;
    background-repeat: no-repeats;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_box{
    color: #fff;
    width: 50%;
    height: 50vh;
    backdrop-filter: blur(10px);
    padding: 4rem 2rem;
    position: relative;
    text-align: center;
}
.container_box a{
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    background-color: #fff;
    color: #0b5e12;
    font-weight: 700;
    padding: 1rem 2rem;
    border-radius: .5rem;
    transition: ease .2s;
}
.container_box a:hover{
    background-color: rgb(205, 205, 205);
}