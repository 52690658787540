a{
    text-decoration: none ;
    color: #fff;
}

.footer{
    background-color: #173824;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
}
.footer ul{
    width: 80%;
    display: inherit;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
    list-style: none;
}
.footer ul li{
    cursor: pointer;
}
.footer li img{
    height: 5rem;
    width: 15rem;
    
}
@media screen and (max-width:1200px){
    .footer{
        padding: 0 2rem;
    }
    .footer ul{
        width: 100%;
    }
    .footer ul li{
        font-size: 1.2rem;
    }
    .footer ul li img{
        width: 10rem;
        height: 2.5rem;
    }
}
@media screen and (max-width:674px){
    .footer{
        padding: 0 .5rem;
    }
    .footer ul{
        width: 100%;
    }
    .footer ul li{
        font-size: .6rem;
    }
    .footer ul li img{
        width: 4rem;
        height: 1.6rem;
    }
}