@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;1,500;1,600;1,700;1,800&family=Roboto:wght@100;300;500&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html{
  max-width: 100%;
}

body{
  font-family: 'Nunito Sans', sans-serif;
  color: #153723;
  user-select: none;
}