.card {
  background-color: #fff;
  max-width: 350px;
  width: 100%;
  height: 303px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -webkit-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -moz-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  border-radius: 1.5rem;
  margin: auto;

}

.card h1 {
  font-weight: 700;
  font-size: 100px;
  color: rgba(21, 55, 35, 0.8);
}
.card h3 {
  font-weight: 500;
  font-size: 30px;
  line-height: 104%;

  text-align: center;

  color: rgba(21, 55, 35, 0.6);
}
@media screen and (max-width:1200px) {
  .card{
    width: 300px;
    margin-right: 1rem;
  }

.card h3 {
  font-weight: 500;
  font-size: 25px;
}
}
@media screen and (max-width:768px) {
  .card{
    width: 100%;
    margin-right: 1rem;
    margin: 0;
  }

.card h3 {
  font-weight: 500;
  font-size: 25px;
}
}
