ul{
    list-style-type: none;
    color: #ccc;
    display: none;
}
.navbar{
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;  
}
.navbar img{
    width: 10rem;
    height: 3rem;
    padding-left: 1rem;
}

.navbar .menu_bar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
}
.navbar .nav_toggle{
    display: none;
}

.menu_bar{
    padding: 0 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    
}
.menu_bar .header_nav{
    display: flex;
}
.menu_bar .header_nav a{
    color: #fff;
    padding: .5rem 1rem;
    text-decoration: none;
    
    position: relative;
    transition: ease-in 0.2s;
}
.menu_bar .header_nav a:hover{
    text-decoration: underline;
    
}
 .drop{
   
    display: flex;
    /* height: 3rem; */
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: .5rem 1rem;
    position: relative;
    
}
/* .menu_bar .header_nav div:hover{
    text-decoration: underline;
} */
 .drop .drop_btn{
    background: transparent;
    color: #fff;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    /* border: 1px solid #fff; */
    padding: 0 0 .2rem 0;
}
/* .menu_bar .header_nav .drop button:hover{
    text-decoration: underline;
} */
.menu_bar .header_nav .drop .dropdown{
    width: 150px;
    height: 55px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 29px;
    left: 0;
    overflow: hidden;
    
}
.menu_bar .header_nav .drop .dropdown a{
    color: #153723;
    /* color: #fff; */
    padding:.3rem .5rem;
    font-size: 1rem;
    height: 50%;
    text-decoration: none;
}
.menu_bar .header_nav .drop .dropdown a:hover{
    /* background-color: #ccc; */
    text-decoration: underline;
}
.menu_bar .not_logged__in a{
    color: #fff;
    padding: 1rem;
    text-decoration: none;
    transition: all .3s;
}
.menu_bar .not_logged__in a:hover{
    text-decoration: underline;
}
.menu_bar .not_logged__in a:last-child:hover{
    color: #153723;
    background-color: #fff;
    overflow: hidden;
    text-decoration: none;
}
.menu_bar .not_logged__in a:last-child{
    padding: .5rem;
    border: 1px solid #fff;
    border-radius: .3rem;
}
.logged_in{
    display: none;
}
.show_logged__in{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu_bar .show_logged__in a{
    color: #fff;
    /* padding: 1rem; */
    text-decoration: none;
    transition: all .3s;
}
.menu_bar .show_logged__in a:hover{
    text-decoration: underline;
}

.hide_logged__in{
    display: none;
}

@media screen and (max-width:1200px) {
    .navbar img{
        width: 6rem;
        height: 1.5rem;
        margin: .7rem 2rem;
    }
    .navbar .menu_bar{
        width: 85%;
        padding: 0 .8rem;
    }
    .menu_bar .header_nav a{
        padding: .5rem .8rem;

    }
     nav a{
        padding: .8rem;
        font-size: 1rem;
    }
    a{
        color: #fff;
        text-decoration: none;
    }
    .drop_btn{
        font-size: 1rem !important;;
    }
}
@media screen and (max-width:768px) {
    .navbar{
        height: 5rem;
        position: fixed;
        top: 0;
        background: rgb(21, 55, 35);
    }
    .navbar .menu_bar{
        display: none;
    }

    .navbar .menu_bar.menu_toggle{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 5rem;
        padding: 10%;
        background: linear-gradient(to bottom, #153723e9, #153723);
        border-top: 1px solid #fff;
        width: 100%;
        height: 100%;
        z-index: 9;

    }
    .menu_bar.menu_toggle .header_nav{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20%;
        
    }
    .menu_bar.menu_toggle .header_nav a:hover{
        text-decoration: underline;
        color: #fff;
    }

    .navbar .nav_toggle{
        display: flex;
        padding: 1rem 2rem;
        font-size: 2rem;
        cursor: pointer;
        
    }
    .drop{
        position: relative;
    }
    /* .drop_btn{
        padding-right: .5rem;
    } */
    .menu_bar .header_nav .drop .dropdown{
        position: absolute;
        left: 87%;
        top: 0;
    }
    .menu_bar .header_nav .drop .dropdown a:hover{
        color: #153723;
    }
    .dropdown li a{
        color: #153723;
    }
    .not_logged__in{
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}