.short_note{
    text-align: start;
}
.short_note h1{
    margin: 0;
    font-size: 7rem;
    color: #fff;
    animation: zoom 3s linear 1;
}
.short_note h2{
    font-size: 2.2rem;
    animation: zoom 3s linear 1;
}
.short_note button{
    margin-top: 2rem;
    font-size: 1.5rem;
    background-color: rgba(250, 250, 250, 0.8);
    color: #000;
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    animation: zoom 3s linear 1;
}
.form{
background-color: white;
color: #344339;
padding: 2rem 4rem;
height: 40%;
border-radius: 10px;
margin:  0 10px 0 10px;
}
.form div{
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
}
.form div label{
    font-size: 1.4rem;
    padding-right: 1rem;
}
.form div input{
    padding: .3rem 1rem;
    width: 100%;
    margin-top: .5rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid #344339;
}
.form button{
    background-color: #344339;
    color: #fff;
    padding: .7rem 1.2rem;
    margin: auto;
    border: none;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
}
.form button:hover{
background-color: #2a5037;
}
@keyframes zoom {
    0%{
        opacity: 0;
        display: none;
    }
    20%{
        opacity: 0.2;
    }
    40%{
        opacity: 0.4;
    }
    60%{
        opacity: 0.6;
    }
    80%{
        opacity: 0.8;
    }
    100%{
        opacity: 1;
    }
}
@media screen and (max-width:1200px) {
    .short_note {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        
    }
    .short_note h1{
        font-size: 6rem;
    }
    .short_note h2{
        font-size: 2.5rem;
    }
    .short_note button{
    
        font-size: 2rem;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
    }
}
@media screen and (max-width:674px){
    .short_note{
        position: relative;
        top: 20%;
    }
     .short_note h1{
        font-size: 3rem;
     }
     .short_note h2{
        font-size: 1.2rem;
     }
     .short_note button{
        margin-top: 1rem;
        font-size: 1rem;
        padding: 1rem 1.2rem;
     }
     .form{
        
        padding: 1rem 2rem;
        border-radius: 8px;
        height: 30%;
        margin:  0 10px 0 10px;
        }
        .form div{
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;
        }
        .form div h2{
            font-size: 1rem;
        }
        .form div label{
            font-size: 1rem;
        }
       
        .form button{

            padding: .5rem 1rem;

            font-size: 1rem;

        }
}