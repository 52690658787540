.trade_card{
    display: block;
    width: 30rem;
    height: 14rem;
    background: linear-gradient(180deg, rgba(21, 55, 35, 0.75) 0%, #153723 100%);
    color: #fff;
    border-radius: 1rem;
    

}
.card_info{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.card_info div:first-child{
    font-size: 2.2rem;
}
.card_info div:last-child{
    font-size: 1.7rem;
    font-weight: 600;
    
}
.trade_card button{
    width: 80%;
    margin-bottom: 1rem;
    padding: 1rem 4rem;
    border-radius: 2rem;
    border: none;
    font-size: 1.5rem;
}
.trade_card button a{
    color: #153723;
    text-decoration: none;
}
@media screen and (max-width:1200px) {
    .trade_card{
        width: 70%;
    }
    .card_info div:last-child{
        font-size: 2rem;
    }
    .trade_card button{
        font-size: 1.8rem;
        padding: 1rem 2rem;
    }
}
@media screen and (max-width:674px){
    .trade_card{
        margin: .5rem auto;
        width: 100%;
        height: 30%;
        padding: 1rem 0.5rem;
    }
    .card_info{
        padding: .5rem;
    }
    .card_info div:first-child{
        font-size: 2rem;
    }
    .card_info div:last-child{
        font-size: 1.5rem;
    }
    .trade_card button{
        font-size: 1.2rem;
        padding: 1rem 2rem;
    }
}