.container{
  background-color: #FFF;
  height: 100vh;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.container_box{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 90%;
  border: 1px solid rgba(255, 255, 255, .2);
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 0 40px 0px rgba(100,100,100,.2);
  -webkit-box-shadow: 0 0 20px 0px rgba(100,100,100,.2);
  -moz-box-shadow: 0 0 40px 0px rgba(100,100,100,.2);
}

.container_box__left{
  padding: 5rem 1rem 1rem 1rem;
  height: 100%;
  border-right: 1px solid rgba(52, 52, 52, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 2;
}

.container_box__logo img{
  width: 8rem;
  margin-bottom: 5rem;
  /* animation: rotation 1s linear 2s infinite; */
}
.container_box__left h1{
  margin-bottom: 1rem;
}
.container_box__left p{
  color: rgb(122, 122, 122);
  margin-bottom: 2rem;
}
.container_box__left p::after{
  content: "";
  font-size: 1.5rem;
  font-weight: 600;
  color: #0aab4d;
  /* text-decoration: underline; */
  font-style: italic;
  animation: description 3s linear infinite ;
}
.container_box__left a{
  width: 80%;
  background-color: #0aab4d;
  padding: .5rem 1rem;
  color: #fff;
  font-size: medium;
  font-weight: 700;
  border-radius: .5rem;
  text-decoration: none;
  margin: 0 auto;
  transition: all .3s;
}
.container_box__left a:hover{
  background-color: #0acd5b;
}

.container_box__right{
  padding: .5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 3;

}
.container_box__right h1{
  color: rgb(122, 122, 122);
  font-size: 1.5rem;
}

.container_box__right form{
  width: 60%;
  margin: 1.5rem 0;
}

.container_box__right form div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:  .5rem;
}
.container_box__right form div .input{
  margin-right: .5rem;
}
.container_box__right form div .error{
  color: red;
  margin-bottom: .5rem;
}
.container_box__right form div button {
  width: 100%;
  height: 50px;
  border: none;
  background-color: #0aab4d;
  padding: .5rem 1rem;
  color: #fff;
  font-size: medium;
  font-weight: 700;
  border-radius: .5rem;
  margin: 1rem auto;
  transition: all .3s;
}
.container_box__right form div button:hover{
  background-color: #0acd5b;
}

.container_box__right form div a{
  color: #2207bc;
  text-decoration: underline;
}
.container_box__rigt div a{
  color: #2207bc;
  text-decoration: underline;
  padding-left: .5rem;
  transition: all .2s ease-in-out;
}
.container_box__right div a:hover{
  color: #4c2ef1;
}
.container_box__right form div:last-child{
  
  display: flex;
}

@keyframes description {
  0%{
    content: "Fast.";
  }
  50%{
    content: "Secure.";
  }
  100%{
    content: "Efficient.";
  }
}
@media screen and (max-width:764px){
  .container{
    padding: 0 1rem;
  }
  .container_box{
    width: 100%;
  }
.container_box__left{
  display: none;
}
.container_box__right form{
  width: 90%;
}
} 