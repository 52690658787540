.trade{
    height: 100%;
    background-image: url(../../images/forest.png);
    background-size: cover;
    background-repeat: no-repeats;
    color: #fff;
}

.bg_shadow{
    height: 100%;
    background-color: rgba(255,255,255,0.8);
}
.credit_container{
    width: 80%;
    padding: 1rem 3rem;
    color: #1E2B22;
    text-align: center;
    margin: auto;
}
.credit_container p{
    font-size: 2.2rem;
    font-weight: 700;
    margin: 3rem 0;
}
.credit_container .trade_box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 3rem;
    margin: 0 auto;
}
@media screen and (max-width:1200px) {

    .credit_container{
        width: 100%;
        padding: 0.5rem;
    }
    .credit_container p{
        font-size: 2rem;
        
    }
    .credit_container .trade_box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:674px){
    .trade{
        height: 120%;
    }
    .bg_shadow{
        height: 120%;
    }
    .credit_container p{
        font-size: 1.4rem;
        margin: 1rem 0;
    }
    .credit_container .trade_box{
        display: flex;
        flex-direction: column;
        gap: .5rem;

    }
}