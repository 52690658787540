  

  
  .table {
    width: 100%;
    max-width: fit-content;
    max-height: fit-content;
    border-spacing: 15px;
    color: #153723;
    /* height: 100%; */
    border-collapse: collapse;
    font-size: 16px;
    align-self: normal;
    position: absolute;
    top: 15%;
    margin-right: .5rem;
    /* overflow: scroll; */
    
  }
  .table thead tr{
    position: sticky;
  }
  .table thead th {
    background-color: #153723;
    color: #ddd;
  }
  
  .table tr {
    margin-bottom: 20px;
    overflow: scroll;
  }
  .table th, .table td  {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
  }
 
  .table tr:nth-child(even){
    background-color: #f5f5f5;
  }
  @media screen and (max-width: 1200px) {
    .table{
        font-size: 14px;
    }
}
@media screen and (max-width: 768px) {
    .table, .table tbody,  .table tr, .table td{
      display: block;
    }
    .table th{
      display: none !important;
    }
    .table tr{
      margin-bottom: 2rem !important;
      width: 100%;
      position: relative;
    }
    .table td{
      text-align: right;
      position: relative;
    }
    .table td::before{
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 40%;
      font-size: 14px;
      text-align: left;
      padding: .5rem 1rem;
      margin-right: 1rem;
    }
}

