.card {
  width: 35%;
  height: 60%;
  background-color: #fff;
  margin: 10px;
  box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -webkit-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  -moz-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
  border-radius: 22px;
  padding: 2rem;
}
.card h2 {
  font-weight: 700;
  font-size: 50px;

  color: rgba(21, 55, 35, 0.8);
}
.card p {
    font-weight: 400;
    font-size: 20px;
  
    color: rgba(21, 55, 35, 0.6);
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 1200px) {
    .card{
      width: 45%;
  }
  }
  @media screen and (max-width: 768px) {
    .card{
      width: 100%;
      height: 100%;
  }
  }
  