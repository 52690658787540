.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    max-height: 100vh;
    background-color: #fff;
    margin: 2rem auto;
    box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
    -webkit-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
    -moz-box-shadow: 4px 4px 25px rgba(30, 42, 34, 0.1);
    border-radius: 1.5rem;
    padding: 2rem;
    overflow: hidden;
  }
  
  



  @media screen and (max-width: 1200px) {
    .app{
      width: 95%;
        height: 100%;
    }
}
@media screen and (max-width: 768px) {
    .app{
        display: flex;
        flex-direction: column;
    }
}