.header {
  height: 100vh;
  background-image: url(../../images/forest.png);
  background-size: cover;
  background-repeat: no-repeats;
  position: relative;
  color: #fff;
}
.container {
  width: 100%;
  height: 100%;
  padding: 8rem 10rem;
  position: relative;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
}
@media screen and (max-width: 1200px) {
  .header {
    padding: 0;
  }
  /* .container {
    width: 100%;
    padding: 1rem;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
}
@media screen and (max-width: 674px) {
  .container {
    padding: 1rem;
  }
}
