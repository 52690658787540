.header {
  height: 10vh;
  background-image: url(../../images/forest.png);
  background-size: cover;
  background-repeat: no-repeats;
  position: relative;
  color: #fff;
}
.container {
  width: 100vw;
  max-width: 100%;
  background-color: rgba(197, 197, 197, 0.2);
  display: flex;
  justify-content: center;
}
.container_box {
  width: 35%;
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  border: 1px solid rgba(100,100,100,0.2); 
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(67,67,67,0.75);
-webkit-box-shadow: 0px 0px 15px 0px rgba(67,67,67,0.75);
-moz-box-shadow: 0px 0px 15px 0px rgba(67,67,67,0.75);
  
}
.container_box__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(100,100,100, 0.2);
}
.container_box__top div:first-child {
  padding: 0.5rem;
}
.container_box__top div h2 {
  font-weight: 100;
  font-size: 1.3rem;
}
.container_box__top .box_remark {
  width: 70%;
  height: 100%;
  border-left: 11px solid #153723;
  background: linear-gradient(to right, #577564, #77a289);
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-left: 0.5rem;
}
.container_box__bottom {
  padding: 2rem 0;
}
.container_box__bottom form div{
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
.container_box__bottom form div label{
  width: 40%;
}
.container_box__bottom form div label span{
  color: rgb(255, 2, 2);
}
.container_box__bottom form div input{
  width: 50%;
  padding: .2rem;
  border: 1px solid rgba(100,100,100,.8);
  border-radius: 3px;
  outline: none;
}
.container_box__bottom form div button{
  width: 40%;
  margin-top: 2rem;
  padding: .5rem 1.5rem;
  background: #577564;
  border: none;
  color: #fff;
  border-radius: .25rem;
  font-size: 1rem;
}

@media screen and (max-width: 1200px) {
  .header {
    /* height: 10vh; */
    padding: 0;
  }
  .container_box{
    width: 55%;
  }
  .container_box__top div h2 {
    font-size: 1.1rem;
  }

}
@media screen and (max-width: 674px) {
  .header {
    height: 10vh;

  }
  .container {
    padding: 1rem;
  }
  .container_box{
    width: 100%;
  }
  .container_box__top{
    margin-bottom: 0;
  }
  .container_box__top div h2 {
    font-size: .8rem;
    font-weight: 400;
  }
  .container_box__top .box_remark{
    font-size: 1rem;
  }
}
