.card {
  width: 40%;
  height: 13.5rem;
  color: #fff;
  background: rgba(21, 55, 35, 0.8);
  box-shadow: 0px 4px 25px rgba(30, 42, 34, 0.25);
  padding: 1.5rem 2.2rem;
  border-radius: 1rem;
  text-align: left;
  margin-bottom: 2rem;
}
.card h2 {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}
.card p {
  font-size: 1rem;
}

@media screen and (max-width:1200px){
  .card{
    width: 80%;
    padding: 1rem 2rem;
    margin-bottom: 1.5rem;
    border-radius: 1rem;


  }
  .card h2{
    font-size: 1.5rem;
  }
  .card p{
    font-size: 1.3rem;
  }
}
@media screen and (max-width:674px){
  .card{
    width: 100%;
    height: 15%;
    padding: .5rem;
    border-radius: 0.7rem;

  }
  .card h2{
    font-size: 1.1rem;
  }
  .card p{
    font-size: .9rem;
  }
}