
.bg_shadow{
    height: 50%;
    background: linear-gradient(to bottom, #E5E5E5,rgba(46, 68, 54, 0.8));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bg_shadow h1{
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    margin-top: 3rem;

}
.bg_shadow a{
    margin: 2rem;
    color: #153723;
    padding: 1rem 4rem;
    border-radius: 2rem;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    background-color: #fff;
    text-decoration: none;
}

@media screen and (max-width:1200px) {
    .bg_shadow h1{
        margin-top: 3rem;
        font-size: 2.5rem;
    }
    .bg_shadow a{
        font-size: 1.8rem;
        padding: 1rem 4rem;
    }
}
@media screen and (max-width:674px) {
    .question{
        height: 100%;
    }
    .bg_shadow h1{
        font-size: 1.7rem;
        margin-top: 2rem;
    }
    .bg_shadow a{
        font-size: 1.2rem;
        margin: 1.5rem;
    }
}