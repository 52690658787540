.container{
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    margin: 2rem auto;
    align-items: center;
    
}
@media screen and (max-width: 1200px) {
    .container{
        width: 95%;
    }
}
@media screen and (max-width: 768px) {
    .container{
        
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}